import React, { useState } from 'react';
import PropTypes from 'prop-types';
import style from './NewFront.module.scss';
import 'swiper/swiper.scss';
import BannerData from './components/BannerData';
import UserExperience from './components/UserExperience';
import GlobalTemp from './components/GlobalTemp';
import PrivacyMent from './components/PrivacyMent/PrivacyMent';
import NewRelatedBlog from './components/NewRelatedBlog/NewRelatedBlog';
import BreadcrumbsSemanticMarkup from '../../components/BreadcrumbsMarkup/BreadcrumbsMarkup';
import FaqSemanticMarkup from '../../components/FaqSemanticMarkup/FaqSemanticMarkup';

const FrontPage = ({
  current: body,
  // pageUid,
  canonical,
  metatitle,
  // lang: pageLang,
  blogData,
}) => {
  const questions = body.filter((item) => item.slice_type === 'questions');
  const contentQuestions = body.filter((item) => item.slice_type === 'content');
  const questionsSliceFaqLists = questions.map((element) => element.items);
  const contentSliceFaqLists = contentQuestions.map((element) => element.items);
  const questionsSliceFaqList = questionsSliceFaqLists.flat();
  const contentSliceFaqList = contentSliceFaqLists.flat();
  const generalFaqList = [...questionsSliceFaqList, ...contentSliceFaqList];
  const [visibleItems, setVisibleItems] = useState(3);
  const handleClickLoadMore = () => {
    setVisibleItems(visibleItems + 3);
  };

  const threeMonthsAgo = new Date();
  threeMonthsAgo.setMonth(threeMonthsAgo.getMonth() - 3);

  const filteredBlogData = blogData?.filter(
    (item) => new Date(item?.node?.data?.date) >= threeMonthsAgo
  );
  return (
    <div className={style.FrontPage}>
      <div>
        {body.map((section, index) => {
          switch (section.slice_type) {
            case 'userexperience':
              return (
                <UserExperience
                  {...section}
                  key={`${section.slice_type}${index}`}
                />
              );
            case 'bannerdata':
              return (
                <BannerData
                  {...section}
                  key={`${section.slice_type}${index}`}
                />
              );
            case 'globaltemplate':
              return (
                <GlobalTemp
                  {...section}
                  key={`${section.slice_type}${index}`}
                />
              );
            case 'privacymanagement':
              return (
                <div className={style.globalcontainer}>
                  <PrivacyMent
                    {...section}
                    key={`${section.slice_type}${index}`}
                  />
                </div>
              );
            default:
              throw new Error(`Unknown section type: ${section.slice_type}`);
          }
        })}
      </div>
      <div className={style.blogContainer}>
        <div className={style.title}>
          <h2>Latest</h2>
          <strong>Blog Posts</strong>
        </div>
        <div className={style.list}>
          {filteredBlogData?.slice(0, visibleItems)?.map((item) => {
            return <NewRelatedBlog {...item} key={item.node.uid} />;
          })}
        </div>

        <div className={style.buttonWrapper}>
          {filteredBlogData?.length >= 3 && visibleItems < 6 && (
            <button
              className={style.viewArticleButton}
              onClick={handleClickLoadMore}
            >
              See More Blog Posts
            </button>
          )}
        </div>
      </div>
      <FaqSemanticMarkup questions={generalFaqList} />
      <BreadcrumbsSemanticMarkup
        pageTitle={metatitle?.text}
        pageUrl={canonical?.text}
      />
    </div>
  );
};

FrontPage.propTypes = {
  current: PropTypes.array.isRequired,
  pageUid: PropTypes.string,
  canonical: PropTypes.object.isRequired,
  metatitle: PropTypes.object.isRequired,
  lang: PropTypes.string.isRequired,
  blogData: PropTypes.array,
};

export default FrontPage;
