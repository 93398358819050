import React, { useEffect, useState } from 'react';
import style from './Bannerdata.module.scss';
import { RichText } from 'prismic-reactjs';
import Swiper from 'react-id-swiper';
import 'swiper/css/swiper.css';
import Prev from './mainfile/prevbutton.js';
import Next from './mainfile/Icon.js';
import { array, object } from 'prop-types';
import Image from '../../../../components/Image/Image.js';

const BannerData = ({ primary, items }) => {
  const { buttontext, plan_id } = primary;
  const [swiper, setSwiper] = useState(null);
  const [index, setIndex] = useState(0);

  useEffect(() => {
    if (swiper) {
      swiper.update();
      setIndex(swiper?.activeIndex - 1);
    }
  }, [swiper]);

  const goNext = () => {
    if (swiper) {
      swiper.slideNext();
      setIndex(index + 1);
    }
  };

  const goPrev = () => {
    if (swiper) {
      swiper.slidePrev();
      setIndex(index - 1);
    }
  };

  const bannerNameForLink =
    items[
      swiper?.activeIndex > items?.length
        ? swiper?.activeIndex - (items?.length + 1)
        : swiper?.activeIndex === 0
        ? items?.length - 1
        : swiper?.activeIndex - 1
    ]?.bannerimg?.alt;

  return (
    <div className={style.container}>
      <div className={style.content}>
        <div className={style.newtitle}>
          <span className={style.swiperPrev} onClick={goPrev}>
            <Prev />
          </span>
          <span className={style.swiperNext} onClick={goNext}>
            <Next />
          </span>
          <div className={style.title}>
            <div className={style.banneritem}>
              <div className={style.bannerimgData}>
                <div className={style.itemsData}>
                  <Swiper getSwiper={setSwiper} {...params}>
                    {items?.map((item, index) => {
                      return (
                        <div key={index}>
                          <div
                            className={`${style.icon} ${
                              style[`icon${index + 1}`]
                            }`}
                          >
                            <Image image={item?.bannerimg} />
                          </div>
                        </div>
                      );
                    })}
                  </Swiper>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={style.buttonWrapper}>
        <a
          className={style.button}
          href={`https://cmp.secureprivacy.ai/onboarding?planId=${plan_id.text}&bannerName=${bannerNameForLink}`}
        >
          <RichText render={buttontext.richText} />
        </a>
      </div>
    </div>
  );
};

const params = {
  slidesPerView: 1,
  spaceBetween: 30,
  loop: true,
  breakpoints: {
    768: {
      slidesPerView: 1,
      spaceBetween: 0,
    },
  },
  effect: 'fade',
  fadeEffect: {
    crossFade: true,
  },
};

BannerData.propTypes = {
  primary: object,
  items: array,
};

export default BannerData;
