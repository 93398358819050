import React from 'react';
const Next = () => (
    <svg width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="0.880859" y="0.39624" width="44" height="44" rx="22" fill="url(#paint0_linear_13570_20616)" />
        <path d="M20.588 29.1034L27.2951 22.3963L20.588 15.6892L19.1738 17.1034L24.4667 22.3963L19.1738 27.6892L20.588 29.1034Z" fill="white" />
        <defs>
            <linearGradient id="paint0_linear_13570_20616" x1="-19.1598" y1="97.2537" x2="84.2846" y2="96.3852" gradientUnits="userSpaceOnUse">
                <stop stop-color="#24B04B" />
                <stop offset="1" stop-color="#0263BC" />
            </linearGradient>
        </defs>
    </svg>

);
export default Next