import React from 'react';
import styles from './UserExperience.module.scss';
import { RichText } from 'prismic-reactjs';
import { array, object } from 'prop-types';
import Image from '../../../../components/Image/Image';

const UserExperience = ({ primary, items }) => {
  const { title, subtitle } = primary;
  return (
    <div className={styles.newpageHero}>
      <div className={styles.container}>
        <div className={styles.title}>
          <RichText render={title.richText} />
          <RichText render={subtitle.richText} />
          <div className={styles.iconslist}>
            {items?.map((item) => {
              return (
                <div className={styles.icon} key={item.icon}>
                  <Image image={item?.usericon} />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

UserExperience.propTypes = {
  primary: object,
  items: array,
};

export default UserExperience;
